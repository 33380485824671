import { FC, useState, useEffect } from 'react';
import CountUp from 'react-countup';

import { getWaitlistCount } from 'src/firebase/waitlist';

const WaitlistNumber: FC = () => {
  const [count, setCount] = useState<number | undefined>();

  const getCount = async () => {
    const count = await getWaitlistCount();
    setCount(count);
  };

  useEffect(() => {
    getCount();
  }, []);

  return (
    <div style={{ margin: '1.5em auto' }}>
      <h3>
        Join waitlist with{' '}
        <span style={{ color: 'rgb(62, 119, 255)' }}>
          {!count && '--'}
          {count && <CountUp end={count} separator="," />}
        </span>{' '}
        members for the multi-chain future
      </h3>
    </div>
  );
};

export default WaitlistNumber;
