import { FC } from 'react';
import { Grid } from '@mui/material';

import DefiDashboard from 'src/assets/images/demo/multichain-defi-dashboard.png';
import MultichainPayment from 'src/assets/images/demo/multichain-payment.png';
import MuchMore from 'src/assets/images/demo/multichain-sdk-more.png';

const MultichainSDK: FC = () => {
  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={4}>
          <h3>Multi-chain DeFi Dashboard</h3>
          <img src={DefiDashboard} alt="" width="90%" />
        </Grid>
        <Grid item xs={12} md={4}>
          <h3>Multi-chain Payment</h3>
          <img src={MultichainPayment} alt="" width="90%" />
        </Grid>
        <Grid item xs={12} md={4}>
          <h3>And Much More...</h3>
          <img src={MuchMore} alt="" width="90%" />
        </Grid>
      </Grid>
    </div>
  );
};

export default MultichainSDK;
