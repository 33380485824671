import { createTheme } from '@mui/material/styles';

const globalStyle = {
  typography: {
    fontFamily: 'Nunito, sans-serif',
    h3: {
      fontWeight: 700
    },
    h5: {
      fontWeight: 700
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none'
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '2em'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          margin: '0 1em',
          borderRadius: '2em'
        }
      }
    }
  }
};

const lightPalette = {
  primary: {
    main: '#000000'
  },
  secondary: {
    main: '#FFFFFF'
  }
};

const darkPalette = {
  primary: {
    main: '#FFFFFF'
  },
  secondary: {
    main: '#000000'
  }
};

const lightTheme = createTheme({
  ...globalStyle,
  palette: lightPalette
});

const darkTheme = createTheme({
  ...globalStyle,
  palette: darkPalette
});

export { lightTheme, darkTheme };
