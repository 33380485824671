import { FC } from 'react';
import { AppBar, Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { StyledLogo } from './styles';
import DeLiquidLogo from 'src/assets/images/logo/deliquid/logoWithNameBlack.png';

const TopNavBar: FC = () => {
  return (
    <AppBar position="static" color="inherit">
      <Container maxWidth="xl" sx={{ display: 'flex' }}>
        <Link to="/">
          <StyledLogo src={DeLiquidLogo} alt="DeLiquid Logo" />
        </Link>
      </Container>
    </AppBar>
  );
};

export default TopNavBar;
