import {
  TopNavBar,
  BottomNavBar,
  ContentBlock,
  Title,
  TextContentBlock,
  LogoList
} from 'src/components';
import homeContents from 'src/contents/home';
import aboutContents from 'src/contents/about';

import MultichainSDK from './MultichainSDK';
import { MainContainer } from './styles';

const HomePage = () => {
  return (
    <div>
      <TopNavBar />
      <MainContainer>
        <ContentBlock {...homeContents.intro} />
        <Title title="Supported Blockchains" />
        <LogoList {...homeContents.support.logos.blockchains} />
        <ContentBlock {...homeContents.feature1} />
        <ContentBlock {...homeContents.feature2} />
        <ContentBlock {...homeContents.feature3} />
        <Title
          title={
            <span>
              <span style={{ color: '#367BFF' }}>Beyond Just A Wallet!</span> DeLiquid&apos;s
              multi-chain SDK can unlock
            </span>
          }
        />
        <MultichainSDK />
        <TextContentBlock {...homeContents.outro} />
      </MainContainer>
      <BottomNavBar socialMedias={aboutContents.socialMedia} />
    </div>
  );
};

export default HomePage;
