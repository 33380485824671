import { FC } from 'react';
import { Grid } from '@mui/material';

import { TopNavBar, BottomNavBar } from 'src/components/NavBar';
import { aboutContents, members } from 'src/contents';
import { primaryColor } from 'src/styles';
import LinkedinLogo from 'src/assets/png/linkedin.png';

import { AboutContainer, MemberContainer, CircularAvatar, MemberTitle } from './styles';

const About: FC = () => {
  const memberList = members.map((member, i) => (
    <Grid item xs={12} md={4} key={member.name + i}>
      <MemberContainer>
        <CircularAvatar src={member.avatar} alt="avatar" width="100px" />

        <Grid container alignItems="center" justifyContent="center">
          <Grid item>
            <MemberTitle>{member.name}</MemberTitle>
          </Grid>
          <Grid item>
            <a href={member.links.linkedin} target="_blank" rel="noopener noreferrer">
              <img src={LinkedinLogo} alt="linkedin" width="30px" style={{ padding: '5px 0 0' }} />
            </a>
          </Grid>
        </Grid>

        <span>{member.title}</span>
      </MemberContainer>
    </Grid>
  ));

  return (
    <div>
      <TopNavBar />

      <AboutContainer>
        <h3>The DeLiquid Vision</h3>
        <h1 style={{ color: primaryColor }}>Build the future of Web3 interoperability</h1>
        <h2>Our Team</h2>
        <Grid container alignItems="center" justifyContent="center" m="1em 0 3em">
          {memberList}
        </Grid>
      </AboutContainer>

      <BottomNavBar socialMedias={aboutContents.socialMedia} />
    </div>
  );
};

export default About;
