import { Grid, Typography } from '@mui/material';
import { LogoListProps } from './types';
import { StyledGridContainer, StyledLogo, StyledNameCountDisplayContainer } from './styles';

const LogoDisplay = (props: LogoListProps) => {
  return (
    <Grid container spacing={1} justifyContent="center">
      {props.chains.map((chain, i) => (
        <Grid item xs={4} md={2} key={i}>
          <StyledLogo src={chain.logo} alt="Logo" />
          <div>{chain.name}</div>
        </Grid>
      ))}
    </Grid>
  );
};

const NameCountDisplay = (props: LogoListProps) => {
  return (
    <StyledNameCountDisplayContainer>
      <Typography variant="h4">{props.count}</Typography>
      <Typography variant="body1" gutterBottom>
        {props.name}
      </Typography>
    </StyledNameCountDisplayContainer>
  );
};

const LogoList = (props: LogoListProps) => {
  return (
    <StyledGridContainer
      container
      spacing={1}
      paddingBottom={10}
      direction={{ xs: 'row', md: 'row-reverse' }}>
      <Grid item xs={12} md={2}>
        <NameCountDisplay {...props} />
      </Grid>
      <Grid item xs={12} md={10}>
        <LogoDisplay {...props} />
      </Grid>
    </StyledGridContainer>
  );
};

export default LogoList;
