import { FC } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import { HomePage, About } from 'src/pages';

const Routes: FC = () => {
  return useRoutes([
    { path: '/', element: <HomePage /> },
    { path: 'about', element: <About /> },
    { path: '*', element: <Navigate to="/" replace /> }
  ]);
};

export default Routes;
