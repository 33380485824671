import { styled, Grid } from '@mui/material';

export const StyledGridContainer = styled(Grid)(() => ({
  maxWidth: '1200px',
  justifyContent: 'center',
  margin: '1em auto'
}));

export const StyledTextContentBlock = styled('div')(({ theme }) => ({
  padding: theme.spacing(6, 0),
  margin: 'auto',
  maxWidth: '500px',
  [theme.breakpoints.down('md')]: {
    padding: '2em 0.5em'
  }
}));

export const StyledImageContentBlock = styled('img')(({ theme }) => ({
  padding: theme.spacing(1),
  maxWidth: '360px'
}));
