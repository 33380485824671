import { FC, ReactNode } from 'react';

import { StyledTitle } from './styles';

interface TitleProps {
  title: string | ReactNode;
}

const Title: FC<TitleProps> = ({ title }) => {
  return (
    <div>
      <StyledTitle>{title}</StyledTitle>
    </div>
  );
};

export default Title;
