import { styled } from '@mui/material';

export const StyledWaitlistFormContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: theme.spacing(2),
  '& > .MuiTextField-root': {
    width: '60%',
    maxWidth: '450px'
  }
}));
