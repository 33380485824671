import { styled } from '@mui/material';

export const StyledTitle = styled('h2')(({ theme }) => ({
  padding: theme.spacing(6, 6),
  margin: 'auto',
  textAlign: 'left',
  [theme.breakpoints.down('md')]: {
    padding: '2em 0.5em'
  }
}));
