import { Typography } from '@mui/material';
import { TextContentBlockProps } from './types';
import { StyledTextContentBlock } from './styles';

const TextContentBlock = (props: TextContentBlockProps) => {
  const titleVariant = props.type === 'major' ? 'h3' : 'h5';

  return (
    <StyledTextContentBlock>
      <Typography align={props.align} variant={titleVariant} gutterBottom>
        {props.title}
      </Typography>
      <Typography align={props.align} paragraph gutterBottom>
        {props.content}
      </Typography>
      {props.otherComponents.map((f, i) => (
        <div key={i}>{f()}</div>
      ))}
    </StyledTextContentBlock>
  );
};

export default TextContentBlock;
