import { StyledTitle } from './styles';
import { IHomeContents } from './types';
import {
  ContentBlockLayout,
  ContentBlockType,
  TextContentBlockAlign
} from 'src/components/ContentBlock/types';
import WaitlistForm from 'src/components/WaitlistForm';
import WaitlistNumber from 'src/pages/HomePage/WaitlistNumber';
import interactWithDapps from 'src/assets/images/demo/interactWithDapps.png';
import sendCrypto from 'src/assets/images/demo/sendCrypto.png';
import privacyImg from 'src/assets/images/demo/privacy.png';
import imgWalletExtensionHome from 'src/assets/images/demo/wallet-extension-home.png';
import EthereumLogo from 'src/assets/images/logo/ethereum.png';
import SolanaLogo from 'src/assets/images/logo/solana.png';
import PolygonLogo from 'src/assets/images/logo/polygon.png';
import BinanceLogo from 'src/assets/images/logo/binance.png';
import AvalancheLogo from 'src/assets/images/logo/avalanche.png';
import CosmosLogo from 'src/assets/images/logo/cosmos.png';

const homeContents: IHomeContents = {
  intro: {
    type: 'major' as ContentBlockType,
    layout: 'text-image' as ContentBlockLayout,
    title: (
      <div>
        <StyledTitle>A Multi-Chain</StyledTitle>
        <StyledTitle>Crypto Wallet For Web3</StyledTitle>
      </div>
    ),
    content:
      'One wallet, multiple blockchains. DeLiquid makes it simple & secure for you to store, send, swap, bridge tokens across multiple blockchains.',
    image: imgWalletExtensionHome,
    imageAlt: 'Demo Dashboard',
    otherComponents: [
      () => (
        <div>
          <WaitlistForm />
          <WaitlistNumber />
        </div>
      )
    ]
  },
  support: {
    text: {
      type: 'minor' as ContentBlockType,
      align: 'left' as TextContentBlockAlign,
      title: 'Supported Blockchains',
      content: '',
      otherComponents: []
    },
    logos: {
      blockchains: {
        name: 'Blockchains',
        count: '6+',
        chains: [
          { name: 'Ethereum', logo: EthereumLogo },
          { name: 'Solana', logo: SolanaLogo },
          { name: 'Polygon', logo: PolygonLogo },
          { name: 'Binance', logo: BinanceLogo },
          { name: 'Avalanche', logo: AvalancheLogo },
          { name: 'Cosmos', logo: CosmosLogo }
        ]
      }
    }
  },
  feature1: {
    type: 'minor' as ContentBlockType,
    layout: 'text-image' as ContentBlockLayout,
    title: 'Store, Send, Swap, Bridge',
    content:
      'DeLiquid makes it safe & easy for you to store, send, receive, swap tokens on multiple blockchains.',
    image: sendCrypto,
    imageAlt: 'Demo Dashboard',
    otherComponents: []
  },
  feature2: {
    type: 'minor' as ContentBlockType,
    layout: 'image-text' as ContentBlockLayout,
    title: 'Interact with DApps',
    content: 'DeLiquid provides a simple & secure way to interact with Web3 applications.',
    image: interactWithDapps,
    imageAlt: 'Demo Dashboard',
    otherComponents: []
  },
  feature3: {
    type: 'minor' as ContentBlockType,
    layout: 'text-image' as ContentBlockLayout,
    title: 'Private & Secure',
    content: 'DeLiquid is a non-custodial wallet. We never have access to any of your data. Ever',
    image: privacyImg,
    imageAlt: 'Demo Payment',
    otherComponents: []
  },
  outro: {
    type: 'minor' as ContentBlockType,
    align: 'center' as TextContentBlockAlign,
    title: 'Join Our Waitlist to Get Early Access',
    content: '',
    otherComponents: [() => <WaitlistForm />]
  }
};

export default homeContents;
