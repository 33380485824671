import { styled, Grid } from '@mui/material';

export const StyledLogo = styled('img')(({ theme }) => ({
  height: theme.spacing(6),
  margin: theme.spacing(1, 0)
}));

export const StyledNameCountDisplayContainer = styled('div')(({ theme }) => ({
  borderLeft: `${theme.spacing(0.2)} dashed black`,
  [theme.breakpoints.down('md')]: {
    borderLeft: 'none'
  }
}));

export const StyledGridContainer = styled(Grid)(() => ({
  maxWidth: '1200px',
  justifyContent: 'center',
  margin: '0 auto'
}));
