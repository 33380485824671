import markAvatar from 'src/assets/png/mark-avatar.png';
import leoAvatar from 'src/assets/png/leo-avatar.png';
// import samAvatar from 'src/assets/png/sam-avatar.png';
import yuxuanAvatar from 'src/assets/png/yuxuan-avatar.png';
import tigerAvatar from 'src/assets/png/tiger-avatar.png';

export const members = [
  {
    name: 'Mark Ding',
    title: 'Co-Founder, Engineering',
    avatar: markAvatar,
    links: {
      linkedin: 'https://www.linkedin.com/in/kailingding/'
    }
  },
  {
    name: 'Leo Lu',
    title: 'Co-Founder, Product',
    avatar: leoAvatar,
    links: {
      linkedin: 'https://www.linkedin.com/in/songanglu/'
    }
  },
  // {
  //   name: 'Sam Ding',
  //   title: 'Co-Founder, Engineering & Design',
  //   avatar: samAvatar,
  //   links: {
  //     linkedin: 'https://www.linkedin.com/in/kaishan-ding/'
  //   }
  // },
  {
    name: 'Yuxuan Fan',
    title: 'Engineering',
    avatar: yuxuanAvatar,
    links: {
      linkedin: 'https://www.linkedin.com/in/yxfan/'
    }
  },
  {
    name: 'Tiger Zhang',
    title: 'Operation & Partnership',
    avatar: tigerAvatar,
    links: {
      linkedin: 'https://www.linkedin.com/in/kailingding/'
    }
  }
];
