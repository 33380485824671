import { FC } from 'react';
import { Grid } from '@mui/material';

import {
  BottomNavContainer,
  BottomNav,
  StyledLogo,
  SocialMediaIcon,
  SocialMediaContainer
} from './styles';
import { SocialMedia } from './types';
import DeLiquidLogo from 'src/assets/images/logo/deliquid/logoWithNameBlack.png';

interface BottomNavBarProps {
  socialMedias: SocialMedia[];
}

const BottomNavBar: FC<BottomNavBarProps> = ({ socialMedias }) => {
  const socialMediaUI = socialMedias.map((socialMedia) => (
    <a key={socialMedia.name} target="_blank" rel="noopener noreferrer" href={socialMedia.url}>
      <SocialMediaIcon src={socialMedia.logo} alt={socialMedia.name} />
    </a>
  ));

  return (
    <BottomNavContainer>
      <BottomNav>
        <Grid container>
          <Grid item xs={12} md={4}>
            <StyledLogo src={DeLiquidLogo} alt="logo" />
            <h5 style={{ margin: '0.5em 2em' }}>@2022 DeLiquid</h5>
            <SocialMediaContainer>{socialMediaUI}</SocialMediaContainer>
          </Grid>
          <Grid item xs={12} md={3} p="1em 2em">
            <h3>Product</h3>
            <a href="/">
              <span>Overview</span>
            </a>
          </Grid>
          <Grid item xs={12} md={3} p="1em 2em">
            <h3>Company</h3>
            <a href="/about">
              <span>About</span>
            </a>
          </Grid>
        </Grid>
      </BottomNav>
    </BottomNavContainer>
  );
};

export default BottomNavBar;
