import { collection, doc, getDoc, getDocs, setDoc, Timestamp } from 'firebase/firestore';

import { db } from '.';

export const addWaitlistEntry = async (email: string) => {
  const col = collection(db, 'waitlist-entries');
  const docRef = doc(col, email);

  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const createdAt = docSnap.data().createdAt.toDate().toDateString().slice(4);
    return {
      success: false,
      message: `The email has already joined the waitlist on ${createdAt}.`
    };
  } else {
    await setDoc(docRef, {
      createdAt: Timestamp.now()
    });
    return {
      success: true,
      message: 'You have successfully joined the waitlist!'
    };
  }
};

export const getWaitlistCount = async () => {
  const querySnapshot = await getDocs(collection(db, 'waitlist-entries'));
  return querySnapshot.size;
};
