import { Grid } from '@mui/material';
import { ContentBlockProps } from './types';
import { StyledGridContainer } from './styles';
import TextContentBlock from './TextContentBlock';
import ImageContentBlock from './ImageContentBlock';

const ContentBlock = (props: ContentBlockProps) => {
  const rowDirection = props.layout === 'text-image' ? 'row' : 'row-reverse';

  return (
    <StyledGridContainer container spacing={1} direction={{ xs: 'column', md: rowDirection }}>
      <Grid item xs={12} md={6}>
        <TextContentBlock
          type={props.type}
          align={'left'}
          title={props.title}
          content={props.content}
          otherComponents={props.otherComponents}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <ImageContentBlock
          src={props.image}
          alt={props.imageAlt}
          width={props.imageWidth ? props.imageWidth : '100%'}
          height={props.imageHeight ? props.imageHeight : 'auto'}
        />
      </Grid>
    </StyledGridContainer>
  );
};

export { TextContentBlock, ImageContentBlock };
export default ContentBlock;
