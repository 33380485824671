import DiscordLogo from 'src/assets/images/logo/discord.png';
import TwitterLogo from 'src/assets/images/logo/twitter.png';
import InstagramLogo from 'src/assets/images/logo/instagram.png';

const aboutContents = {
  pages: {
    product: [{ name: 'Overview', url: '/' }],
    company: [{ name: 'About', url: '/about' }]
  },
  socialMedia: [
    { name: 'Discord', logo: DiscordLogo, url: 'https://discord.gg/mfEqvc5fwq' },
    { name: 'Twitter', logo: TwitterLogo, url: 'https://twitter.com/realDeLiquid' },
    { name: 'Instagram', logo: InstagramLogo, url: 'https://www.instagram.com/deliquidofficial/' }
  ]
};

export default aboutContents;
