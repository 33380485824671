import { styled } from '@mui/material';

export const AboutContainer = styled('div')({
  maxWidth: '1200px',
  margin: '0 auto'
});

export const MemberContainer = styled('div')({
  width: '300px',
  margin: '1em auto',
  padding: '1em',
  backgroundColor: '#F5F5F7',
  borderRadius: '10px',
  alignItems: 'center'
});

export const CircularAvatar = styled('img')({
  margin: '0.2em',
  borderRadius: '50%'
});

export const MemberTitle = styled('h3')({
  margin: '0 0.5em'
});
