import React, { useState, useRef, useEffect } from 'react';
import { debounce } from 'lodash';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import { WaitlistDialogProps } from './types';
import { StyledWaitlistFormContainer } from './styles';
import { addWaitlistEntry } from 'src/firebase/waitlist';
import { validateEmail } from 'src/utils/validators';

const WaitlistDialog = (props: WaitlistDialogProps) => {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="waitlist-dialog-title"
        aria-describedby="waitlist-dialog-content">
        <DialogTitle id="alert-dialog-title">{props.success ? 'Success' : 'Error'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-content">{props.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={props.handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const WaitlistForm = () => {
  const [email, setEmail] = useState('');
  const [resSuccess, setResSuccess] = useState(false);
  const [resMessage, setResMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  // debounced submit handler
  const emailRef = useRef('');
  emailRef.current = email;

  const debouncedHandleSubmit = useRef(
    debounce(() => {
      if (validateEmail(emailRef.current)) {
        addWaitlistEntry(emailRef.current)
          .then((data) => {
            setResSuccess(data.success);
            setResMessage(data.message);
            setDialogOpen(true);
          })
          .catch(() => {
            setResSuccess(false);
            setResMessage('An error occurred when joining the waitlist. Please try again later.');
            setDialogOpen(true);
          });
      } else {
        setResSuccess(false);
        setResMessage('The email is invalid.');
        setDialogOpen(true);
      }
    }, 200)
  ).current;

  useEffect(() => () => debouncedHandleSubmit.cancel(), [debouncedHandleSubmit]);

  const handleSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    debouncedHandleSubmit();
  };

  // other handlers
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    setEmail(event.target.value);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setTimeout(() => {
      // avoid removing dialog contents before closing animation ends
      setResSuccess(false);
      setResMessage('');
    }, 200);
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <StyledWaitlistFormContainer>
        <TextField size="small" name="email" label="Email" onChange={handleEmailChange} />
        <Button variant="contained" type="submit">
          Join Waitlist
        </Button>
        <WaitlistDialog
          open={dialogOpen}
          handleClose={handleDialogClose}
          success={resSuccess}
          message={resMessage}
        />
      </StyledWaitlistFormContainer>
    </Box>
  );
};

export default WaitlistForm;
