import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyAiQe5EeW5nnJlFUer2UYSdMLHOKbIHzoU',
  authDomain: 'deliquid-42724.firebaseapp.com',
  databaseURL: 'https://deliquid-42724-default-rtdb.firebaseio.com',
  projectId: 'deliquid-42724',
  storageBucket: 'deliquid-42724.appspot.com',
  messagingSenderId: '760021181057',
  appId: '1:760021181057:web:e41b57dec84f8ae1a76380',
  measurementId: 'G-SNWDKHPX6T'
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
getAnalytics(app);

export { db };
export default app;
