import { styled } from '@mui/material';

export const StyledLogo = styled('img')(({ theme }) => ({
  margin: theme.spacing(3),
  height: theme.spacing(5)
}));

export const BottomNavContainer = styled('div')(() => ({
  backgroundColor: '#F5F5F7',
  minHeight: '300px'
}));

export const BottomNav = styled('div')(() => ({
  maxWidth: '1200px',
  justifyContent: 'center',
  margin: '1em auto',
  textAlign: 'left'
}));

export const SocialMediaIcon = styled('img')(({ theme }) => ({
  height: theme.spacing(4),
  margin: theme.spacing(1)
}));

export const SocialMediaContainer = styled('div')(() => ({
  display: 'flex',
  margin: '0 1em'
}));
